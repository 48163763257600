import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function Home() {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f0f8ff", // dezenter Hintergrund (helles Blau)
        minHeight: "100vh",
        margin: 0,
        padding: 0,
      }}
    >
      <div style={{ textAlign: "center", paddingTop: "50px" }}>
        <h1 style={{ marginBottom: "20px", color: "#333" }}>
          Manachlux UG (haftungsbeschränkt)
        </h1>
        <p style={{ marginBottom: "20px", color: "#666" }}>
          Hier wird gerade umgebaut...
        </p>
        <Link
          to="/impressum"
          style={{ textDecoration: "none", color: "#0077cc" }}
        >
          Impressum
        </Link>
      </div>
    </div>
  );
}

function Impressum() {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f0f8ff",
        minHeight: "100vh",
        margin: 0,
        padding: "50px",
      }}
    >
      <div style={{ textAlign: "left" }}>
        <h2 style={{ marginBottom: "20px", color: "#333" }}>Impressum</h2>
        <p style={{ marginBottom: "20px", color: "#666" }}>
          <div className="centering impress-margin">
            <p>Manachlux UG (haftungsbeschränkt)</p>{" "}
            <p>Besselstr. 25, 68219 Mannheim</p>
            <p>
              Eingetragen im Handelsregister - Registergericht: Mannheim,
              Registernummer HRB 733705
            </p>
            <p>Vertreten durch Geschäftsführer David Sheikh</p>{" "}
            <p>Umsatzsteuer-Identifikationsnummer: DE325044873</p>{" "}
            <p>Telefon: 0621 97688197</p>
            <p>Email: manachlux@gmail.com</p>
            <p>
              Verantwortlich für den Inhalt nach §55 Abs 2 RStV: David Sheikh
            </p>
            <p>
              Die Europäische Kommission stellt eine Online-Plattform zur
              Streitbeilegung bereit, die Sie unter{" "}
              <span>
                <a
                  href="https://ec.europa.eu/consumers/odr"
                  target="manachlux.com"
                  rel="noopener noreferrer"
                >
                  https://ec.europa.eu/consumers/odr
                </a>
              </span>{" "}
              finden. Wir sind zur Teilnahme an einem Streitbeilegungsverfahren
              vor einer Verbraucherschlichtungsstelle nicht verpflichtet und
              nicht bereit.
            </p>
          </div>
        </p>
        <Link to="/" style={{ textDecoration: "none", color: "#0077cc" }}>
          Zur Startseite
        </Link>
      </div>
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/impressum" element={<Impressum />} />
      </Routes>
    </Router>
  );
}
